import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import InstagramCarousel from '../instagram/InstagramCarousel';
import ProfileMap from './ProfileMap';
import { getProfile, getUser, getTags, getUserPosts } from '../../utils/api.jsx';

const ProfilePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setNumber] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [tags, setTags] = useState([]);
  const [profileError, setProfileError] = useState('');
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await getUser(id);
          if (response && !response.errors) {
            setUsername(response.username);
            setEmail(response.email);
            setNumber(response.phone_number);
          } else {
            setProfileError(response.errors.join(", "));
          }

          const profileResponse = await getProfile(id);
          if (profileResponse && !profileResponse.errors) {
            setWebsite(profileResponse.website);
            setTiktok(profileResponse.tiktok);
            setDescription(profileResponse.description);
            setTagIds(profileResponse.tag_ids);
          } else {
            setProfileError(profileResponse.errors.join(", "));
          }

          const tagResponse = await getTags();
          if (tagResponse && !tagResponse.errors) {
            setTags(tagResponse || []);
          } else {
            setProfileError(tagResponse.errors.join(", "));
          }

          const postsResponse = await getUserPosts(id, currentPage, 10);
          if (postsResponse && !postsResponse.errors) {
            setPosts(postsResponse.posts);
            setTotalPages(postsResponse.total_pages);
          } else {
            setProfileError(postsResponse.errors.join(", "));
          }

        } catch (error) {
          setProfileError("An error occurred while fetching data.");
        }
      };
  
      fetchData();
    }
  }, [id, currentPage]);

  const handlePostClick = (postId) => {
    navigate(`/post/${postId}`);
  };

  const handlePrevious = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  const handleNext = () => {
    setCurrentPage(Math.min(totalPages, currentPage + 1));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const matchedTags = tags.filter(tag => tagIds.includes(tag.id));
  const tagsString = matchedTags.map(tag => tag.name).join(', ');

  const fullUrl = website ? (website.startsWith('http://') || website.startsWith('https://') ? website : `https://${website}`) : '';
  const fullTikTok = tiktok ? (tiktok.startsWith('http://') || tiktok.startsWith('https://') ? tiktok : `https://${tiktok}`) : '';
  
  return (
    <div className="profile-page-container">
      <div className="main-content">
        <div className="middle-section">
          <div className="content-container">
            <div className="header-section">
              <h2 className="profile-page-title">{username}'s Profile Page</h2>
              <span className="contact-detail"> Email: {email}</span> <span className="contact-detail"> Number: {phone_number}</span>
            </div>

            <div className="description-section">
              <h3 style={{ marginTop: 0 }}>Description</h3>
              <p>{description}</p>
              <div className="links">
                <a className="link" href={fullUrl} target="_blank">Website</a>
                <a className="link" href={fullTikTok} target="_blank" rel="noopener noreferrer">TikTok</a>
              </div>
              <div className="tags">
                <h3>Tags:</h3>
                <p>{tagsString || "No Tags Found"}</p>
              </div>
            </div>

            <div className="carousel-section">
              <InstagramCarousel userId={id} />
            </div>

            <div className="post-container">
              {/* <h3>Posts</h3> */}
              <div className="pagination">
                <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
                {renderPageNumbers()}
                <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
              </div>

              <div className="posts">
                {posts.length > 0 ? posts.map((post) => (
                  <div key={post.id} className="post" onClick={() => handlePostClick(post.id)}>
                    <h4 className="post-title">{post.title}</h4>
                    <p className="post-content">{post.content}</p>
                  </div>
                )) : <p>No posts found.</p>}
              </div>

              <div className="pagination">
                <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
                {renderPageNumbers()}
                <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
              </div>
            </div>

            {profileError && <p className="error">{profileError}</p>}
          </div>
        </div>
        <div className="map-container">
          <ProfileMap id={id} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
