import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/Authentication';
import LoginPage from './components/user/LoginPage';
import Index from './components/Index';
import RegisterPage from './components/user/RegisterPage';
// import NavigationBar from './components/general/NavBar';
import PageLayout from './components/general/PageLayout';
import CreatePostPage from './components/post/CreatePostPage';
import PostPage from './components/post/PostPage';
import PrivacyPolicy from './components/general/PrivacyPolicy';
import EditProfile from './components/user/EditProfile';
import ProfilePage from './components/user/ProfilePage';
import EditAccount from './components/user/EditAccount';
import FilteredPosts from './components/general/FilteredPosts';
import ProfilesMap from './components/profile/ProfilesMap';
import ContactUs from './components/general/ContactUs';
import TermsOfService from './components/general/TermsOfServices';
import AboutUs from './components/general/AboutUs';
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <AuthProvider>
      <Router>
        {/* <NavigationBar /> */}
        <Routes>
          <Route path="/" element={<PageLayout><Index /></PageLayout>} />
          <Route path="/login" element={<PageLayout><LoginPage /></PageLayout>} />
          <Route path="/signup" element={<PageLayout><RegisterPage /></PageLayout>} />
          <Route path="/post" element={<PageLayout><CreatePostPage /></PageLayout>} />
          <Route path="/post/:id" element={<PageLayout><PostPage /></PageLayout>} />
          <Route path="/privacy" element={<PageLayout><PrivacyPolicy /></PageLayout>} />
          <Route path="/editprofile" element={<PageLayout><EditProfile /></PageLayout>} />
          <Route path="/editaccount" element={<PageLayout><EditAccount /></PageLayout>} />
          <Route path="/profile/:id" element={<PageLayout><ProfilePage /></PageLayout>} />
          <Route path="/filteredposts/:ids" element={<PageLayout><FilteredPosts /></PageLayout>} />
          <Route path="/findastylist" element={<PageLayout><ProfilesMap /></PageLayout>} />
          <Route path="/contactus" element={<PageLayout><ContactUs /></PageLayout>} />
          <Route path="/termsofservice" element={<PageLayout><TermsOfService /></PageLayout>} />
          <Route path="/aboutus" element={<PageLayout><AboutUs /></PageLayout>} />
        </Routes>
      </Router>
      <SpeedInsights />
    </AuthProvider>
  );
}

export default App;
