import '../GeneralStyle.css'
import React from 'react';
import SideBar from './SideBar'
import NavigationBar from './NavBar';

const PageLayout = ({ children }) => {
  return (
    <>
      <NavigationBar />
      <div className="page-container">
        <div className="content-wrap">
          <div className='setup'>
            <SideBar />
          </div>
          <div className='content'>
            {children}
            </div>
          </div>
      </div>
    </>
  );
}

export default PageLayout;
