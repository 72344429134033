import React, { useState, useEffect } from 'react';
import { getTags } from '../../utils/api.jsx';

const Sidebar = () => {
  const [tags, setTags] = useState([]);
  const [errors, setErrors] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTags();
        setTags(response);
      } catch {
        setErrors('Error whilst trying to get tags');
      }
    }

    fetchData();
  }, []);

  return (
    <div className="sidebar">
      <nav>
        <ul style={{ padding: 0, margin: 0 }}>
          <a href={`/`} className="sidebar-link">
            <div className="nav-button">Home</div>
          </a>
        </ul>

        {tags && tags.length > 0 ? (
          <ul style={{ padding: 0, margin: 0 }}>
            {tags.map((tag) => (
              <li key={tag.id} style={{ listStyleType: 'none' }}>
                <a href={`/filteredposts/${tag.id}`} className="sidebar-link">
                  <div className="nav-button">{tag.name}</div>
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p>No Tags Available</p>
        )}

        <ul style={{ padding: 0, margin: 0 }}>
          <li style={{ listStyleType: 'none' }}>
            <a href={`/findastylist`} className="sidebar-link">
              <div className="nav-button">Hair Stylists</div>
            </a>
          </li>
        </ul>

        <ul style={{ padding: 0, margin: 0 }}>
          <li style={{ listStyleType: 'none' }}>
            <a href={`/aboutus`} className="sidebar-link">
              <div className="nav-button">About Us</div>
            </a>
          </li>
        </ul>

        <ul style={{ padding: 0, margin: 0 }}>
          <li style={{ listStyleType: 'none' }}>
            <a href={`/contactus`} className="sidebar-link">
              <div className="nav-button">Contact Us</div>
            </a>
          </li>
        </ul>

        <ul style={{ padding: 0, margin: 0 }}>
          <li style={{ listStyleType: 'none' }}>
            <a href={`/termsofservice`} className="sidebar-link">
              <div className="nav-button">Terms Of Service</div>
            </a>
          </li>
        </ul>

        <ul style={{ padding: 0, margin: 0 }}>
          <li style={{ listStyleType: 'none' }}>
            <a href={`/privacy`} className="sidebar-link">
              <div className="nav-button">Privacy Policy</div>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
