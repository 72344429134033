import React, { useState } from 'react';
import '../GeneralStyle.css';
import { useAuth } from '../../context/Authentication';
import PlusIcon from './PlusIcon';
import { useNavigate } from "react-router-dom";
import CustomDropdown from './MyProfile';
import { search as searchApi } from '../../utils/api';
import { Link } from 'react-router-dom'; // Import Link for navigation

const NavigationBar = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ users: [], posts: [] });
  const [typingTimeout, setTypingTimeout] = useState(0);

  const iconOnClick = () => {
    navigate('/post');
  };

  const handleSearch = async (query) => {
    if (query.length < 3) {
      setResults({ users: [], posts: [] });
      return;
    }
    const data = await searchApi(query);
    setResults(data);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      handleSearch(value);
    }, 300)); // Adjust the debounce time as needed
  };

  const clearSearch = () => {
    setQuery('');
    setResults({ users: [], posts: [] });
  };

  return (
    <div className='navbar'>
      <div className='spacer'>
        <a href="/">
          <img className="home" src="logo.png" alt="Home" />
        </a>
      </div>

      <div className="search-container">
        <input
          type="text"
          className='search'
          placeholder="Search..."
          value={query}
          onChange={handleInputChange}
        />
        {query.length >= 3 && (
          <div className="search-results">
            {results.users.length > 0 && (
              <div>
                <h3>User Results:</h3>
                {results.users.map(user => (
                  <Link key={user.id} to={`/profile/${user.id}`} onClick={clearSearch}>
                    <div className="search-result-item">{user.username}</div>
                  </Link>
                ))}
              </div>
            )}
            {results.posts.length > 0 && (
              <div>
                <h3>Post Results:</h3>
                {results.posts.map(post => (
                  <Link key={post.id} to={`/post/${post.id}`} onClick={clearSearch}>
                    <div className="search-result-item">{post.title}</div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    
      <div>
        {isAuthenticated ? (
          <>
            <PlusIcon color='grey' size='30' onClick={iconOnClick} />
            <CustomDropdown />
          </>
        ) : (
          <a className="login" href="/login">Login</a>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
