import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFIlteredPosts } from '../..//utils/api.jsx';
import { useParams } from 'react-router-dom';
// import "./post/PostStyle.css";

const FilteredPosts = () => {
  let { ids } = useParams();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const { posts, total_pages } = await getFIlteredPosts(ids ,currentPage);
      setPosts(posts);
      setTotalPages(total_pages);
    };

    fetchData();
  }, [currentPage]);  // Depend on currentPage to refetch when it changes

  const handlePostClick = (postId) => {
    navigate(`/post/${postId}`);
  };

  const handlePrevious = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  const handleNext = () => {
    setCurrentPage(Math.min(totalPages, currentPage + 1));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="post-container">
      <div className="pagination">
        <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
        {renderPageNumbers()}
        <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
      </div>

      <div className="posts">
        {posts.map((post) => (
          <div key={post.id} className="post" onClick={() => handlePostClick(post.id)}>
            <span className='creator-tag'>By: {post.created_by}</span>
            <h3 className='post-title'>{post.title}</h3>
            <p className='post-preview'>{post.content}</p>
          </div>
        ))}
      </div>

      <div className="pagination">
        <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
        {renderPageNumbers()}
        <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
      </div>
    </div>
  );
};

export default FilteredPosts;
