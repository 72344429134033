import React, { useState, useEffect } from "react";
import { getUser, editUser } from '../../utils/api.jsx';

const EditAccount = () => {
  const user_id = localStorage.getItem('userId');
  const [userError, setUserError] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [actualPassword, setActualPassword] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    if (user_id) {
      const fetchData = async () => {
        const response = await getUser(user_id);
  
        if (response && !response.errors) {
          setUsername(response.username);
          setEmail(response.email);
          setNumber(response.phone_number);
          setActualPassword(response.password);
          setPassword('');
          setConfirmPassword('');
          setProfilePictureUrl(response.profile_picture_url);
        } else {
          let errors = "";
          for (let i = 0; i < response.errors.length; i++) {
            errors += response.errors[i] + ", ";
          }
          setUserError(errors);
        }
      };
  
      fetchData();
    }
  }, [user_id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const maxSize = 2 * 1024 * 1024; // 2MB limit

      if (!allowedExtensions.includes(fileExtension)) {
        setFileError("Invalid file type. Only .jpg, .jpeg, .png, .gif are allowed.");
        setProfilePicture(null);
      } else if (file.size > maxSize) {
        setFileError("File size exceeds 2MB.");
        setProfilePicture(null);
      } else {
        setFileError('');
        setProfilePicture(file);
      }
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setUserError('');
    
    if (password !== confirmPassword) {
      setUserError('Passwords do not match.');
      return;
    }
  
    if (fileError) {
      setUserError(fileError);
      return;
    }
  
    setLoading(true); // Start loading when the form is submitted
  
    try {
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('username', username);
      formData.append('email', email);
      formData.append('phone_number', phone_number);
      formData.append('password', password);
  
      if (profilePicture) {
        formData.append('profile_picture', profilePicture);
      }
  
      const response = await editUser(formData);
      if (response.ok) {
        const data = await response.json();
        setEmail(data.email);
        setNumber(data.phone_number);
        setUserError('User updated successfully.');
        setProfilePictureUrl(data.profile_picture_url);  // Update the profile picture in the state
  
        // Update profile picture in localStorage and trigger re-render in DropdownButton
        localStorage.setItem('profilePictureUrl', data.profile_picture_url);
        window.dispatchEvent(new Event('profilePictureUpdated')); // Custom event to trigger DropdownButton re-render
      } else {
        const errorData = await response.json();
        setUserError(errorData.errors);
      }
    } catch (error) {
      setUserError('An unexpected error occurred.');
      console.error(error);
    } finally {
      setLoading(false); // Stop loading when the submission is done
    }
  };

  return (
    <div className="container">
      <form className="form" onSubmit={handleEditSubmit}>
        <h2 className="title">Edit User</h2>
        <div className="form-group">
          {profilePictureUrl && (
            <img 
              src={profilePictureUrl} 
              alt="Profile" 
              className="profile-picture" 
            />
          )}
        </div>

        <div className="form-group">
          <input
            placeholder="Username"
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            readOnly
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Phone Number"
            type="tel"
            id="number"
            value={phone_number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Password"
            type="password"
            id="password"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
        </div>
        <div className="form-group">
          <input
            placeholder="Confirm Password"
            type="password"
            id="confirmPassword"
            value={confirmPassword || ''}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
          />
        </div>
        <p className="center-text" htmlFor="profilePicture">Profile Picture</p>
        <div className="form-group">
         
          <input 
            type="file" 
            id="profilePicture" 
            accept=".jpg,.png,.jpeg,.gif"
            onChange={handleFileChange} 
          />
          {fileError && <p className="center-text">{fileError}</p>}
        </div>
        {userError && <p className="center-text">{userError}</p>}
        <div className="form-group">
          <button type="submit" className="button" disabled={loading}>
            {loading ? 'Saving...' : 'Edit'} {/* Change button text based on loading state */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAccount;
