import React, { useState } from "react";
import { createComment } from '../../../utils/api';
import { useParams } from 'react-router-dom';
import './CommentStyle.css';

const CommentBox = ({ onAddComment }) => {
  const [comment, setComment] = useState(''); 
  const [error, setError] = useState('');
  const username = localStorage.getItem('username');
  let { id } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
  
    const response = await createComment(comment, username, id);
    if (response === true) {
      const newComment = {
        created_by: username,
        comment: comment,
        created_at: new Date().toISOString() // Set current timestamp
      };
      onAddComment(newComment);
      setComment('');
    } else {
      const errorData = await response.json();
      setError(errorData.message || 'Failed to post comment.');
    }
  };

  return (
    <div className="comment-container">
      <div className="comment">
      <form onSubmit={handleSubmit} className="comment-form">
        <textarea
          className="comment-input"
          value={comment}
          onChange={e => setComment(e.target.value)}
          placeholder="Write a comment..."
          required
        />
        <button type="submit" className="comment-submit">Post Comment</button>
      </form>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default CommentBox;

