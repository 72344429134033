import React, { useEffect } from 'react';
import config from '../../config'; // Importing the config file

const ConnectInstagramButton = ({ setMessage }) => {
  const handleConnectInstagram = async (e) => {
    e.preventDefault(); // Prevent form submission
    const userId = localStorage.getItem('userId');
    try {
      const response = await fetch(`${config.apiBaseUrl}/instagram/connect?user_id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        window.open(data.instagram_url, '_blank', 'width=600,height=700'); // Opens in a new window
      } else {
        const errorText = await response.text();
        console.error('Error connecting to Instagram:', errorText);
        setMessage('Error connecting to Instagram');
      }
    } catch (error) {
      console.error('Error connecting to Instagram:', error);
      setMessage('Error connecting to Instagram');
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.message === 'Successfully connected to Instagram') {
        setMessage('Successfully connected to Instagram!');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setMessage]);

  return (
    <button className="button" onClick={handleConnectInstagram}>
      Connect Instagram
    </button>
  );
};

export default ConnectInstagramButton;
