import React, { useState } from 'react';
import { useAuth } from '../../context/Authentication'; 
import { useNavigate } from 'react-router-dom';
import './UserStyle.css';


const LoginPage = () => {
  const navigate = useNavigate(); 
  const [email, setEmail] = useState(''); // State to store the email input
  const [password, setPassword] = useState(''); // State to store the password input
  const [error, setError] = useState(''); // State to store any error message
  const { login } = useAuth(); // Destructure the login function from the context

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setError(''); // Reset error message
  
    const result = await login(email, password);
  
    if (result.success) {
      navigate('/');
    } else {
      // Update the error state with the backend-provided error message
      setError(result.message || 'Failed to login. Please check your credentials and try again.');
    }
  };  

  return (
    <div className="container">  
      <form className="form" onSubmit={handleSubmit}>
        <h2 className="title">Log In</h2>
        <div className="form-group">
          <input
            placeholder='Email'
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            placeholder='Password'
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-options">
          {/* forgotten password */}
        </div>
        <div className='form-group'>
          {error && <div className="error">{error}</div>}
        </div>
        <div className="form-group">
          <button type="submit" className="button">Log In</button>
        </div>
        <div className="signup-link">
          Don't have an account? 
          <br />
          <a href="/signup">Sign up</a>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
