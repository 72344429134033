import React, { useState, useEffect } from 'react';
import '../GeneralStyle.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/Authentication';

const DropdownButton = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  // Use state to manage profile picture URL, initialized from localStorage
  const [profilePictureUrl, setProfilePictureUrl] = useState(localStorage.getItem('profilePictureUrl') || 'default-profile.png');
  const username = localStorage.getItem('username');
  const userid = localStorage.getItem('userId');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = async (event) => {
    event.preventDefault();
    await logout();
    setIsOpen(false);
    navigate('/login', { replace: true });
  };

  const handleProfile = async (event) => {
    event.preventDefault();
    setIsOpen(false);
    navigate('/editprofile', { replace: true });
  };

  const handleAccount = async (event) => {
    event.preventDefault();
    setIsOpen(false);
    navigate('/editaccount', { replace: true });
  };

  const handleMyprofile = async (event) => {
    event.preventDefault();
    setIsOpen(false);
    navigate(`/profile/${userid}`, { replace: true });
  };

  // Manually trigger re-render when profile picture is updated in EditAccount
  useEffect(() => {
    const handleProfilePictureUpdated = () => {
      const updatedProfilePictureUrl = localStorage.getItem('profilePictureUrl');
      if (updatedProfilePictureUrl) {
        setProfilePictureUrl(updatedProfilePictureUrl);
      }
    };

    // Listen for the custom event
    window.addEventListener('profilePictureUpdated', handleProfilePictureUpdated);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener('profilePictureUpdated', handleProfilePictureUpdated);
    };
  }, []);

  return (
    <div className="profile-dropdown">
      <div className="profile-container" onClick={toggleDropdown}>
        <img src={profilePictureUrl} alt="Profile" className="profile-picture-icon" />
        <span className="username-text">{username}</span>
      </div>
      {isOpen && (
        <ul className="dropdown">
          <li onClick={handleProfile}>Edit Profile</li>
          <li onClick={handleAccount}>Edit Account</li>
          <li onClick={handleLogout}>Log Out</li>
          <li onClick={handleMyprofile}>My Profile</li>
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
