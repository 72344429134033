import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import { getProfile } from '../../utils/api.jsx';

const libraries = ['places'];

const ProfileMap = ({ id }) => {
  const [radius, setRadius] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [route, setRoute] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [profileError, setProfileError] = useState('');

  useEffect(() => {
    const fetchProfileAddress = async () => {
      const response = await getProfile(id);
      if (response && !response.errors) {
        setRadius(response.radius);
        setStreetNumber(response.street_number);
        setRoute(response.route);
        setCity(response.city);
        setCounty(response.county);
        setPostalCode(response.postal_code);
        setCountry(response.country);
      } else {
        setProfileError(response.errors.join(', '));
      }
    };

    fetchProfileAddress();
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    if (streetNumber && route && city && county && postalCode && country) {
      const geocoder = new window.google.maps.Geocoder();
      const fullAddress = `${streetNumber} ${route}, ${city}, ${county}, ${postalCode}, ${country}`;
      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (isMounted && status === 'OK') {
          const location = results[0].geometry.location;
          setCoordinates({ lat: location.lat(), lng: location.lng() });
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }

    return () => {
      // Cleanup by resetting state or any other necessary cleanup
      isMounted = false;
      setCoordinates({ lat: null, lng: null });
    };
  }, [streetNumber, route, city, county, postalCode, country]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
      preventGoogleFontsLoading={true}
    >
      {coordinates.lat && coordinates.lng && (
        <GoogleMap
          key={id} // Use the profile ID as the key to force a re-render on profile change
          mapContainerStyle={{ height: '300px', width: '300px' }}
          center={coordinates}
          zoom={10}
        >
          <Marker position={coordinates} />
          <Circle
            center={coordinates}
            radius={radius * 1000}
            options={{
              fillColor: '#AA0000',
              fillOpacity: 0.2,
              strokeColor: '#AA0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        </GoogleMap>
      )}
      {profileError && <div>{profileError}</div>}
    </LoadScript>
  );
};

export default ProfileMap;
