import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { getProfiles } from '../../utils/api.jsx';

const libraries = ['places'];

const AllProfilesMap = () => {
  const [addresses, setAddresses] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [mapError, setMapError] = useState('');
  const [selectedArea, setSelectedArea] = useState('england');
  const [viewMode, setViewMode] = useState('map'); // 'map' or 'list'
  const mapRef = useRef(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false); // Track if Google script is loaded

  const areas = {
    england: { lat: 52.3555, lng: -1.1743, zoom: 6 },
    london: { lat: 51.5007, lng: -0.1246, zoom: 14 }, // Big Ben, London
    essex: { lat: 51.7360, lng: 0.4798, zoom: 12 }, // Chelmsford, Essex
    kent: { lat: 51.1485, lng: 0.8738, zoom: 14 }, // Ashford, Kent
  };

  // Fetch profiles after the Google Maps script is loaded
  useEffect(() => {
    if (!isScriptLoaded) return;

    const fetchProfiles = async () => {
      let response = await getProfiles();
      if (response && !response.errors) {
        const fullAddresses = response.map(profile => {
          // Ensure that address components are valid and non-empty
          const address = [
            profile.street_number || '',
            profile.route || '',
            profile.city || '',
            profile.county || '',
            profile.postal_code || '',
            profile.country || ''
          ].filter(Boolean).join(', '); // Filter out empty components

          return { ...profile, fullAddress: address };
        });

        // Filter profiles with valid addresses only
        const validProfiles = fullAddresses.filter(profile => profile.fullAddress);

        setAddresses(validProfiles);

        const geocoder = new window.google.maps.Geocoder();
        const coordinatePromises = validProfiles.map(profile =>
          new Promise((resolve, reject) => {
            geocoder.geocode({ address: profile.fullAddress }, (results, status) => {
              if (status === 'OK') {
                const location = results[0].geometry.location;
                resolve({ ...profile, coordinates: { lat: location.lat(), lng: location.lng() } });
              } else {
                console.error(`Geocode failed for address ${profile.fullAddress} with status: ${status}`);
                resolve(null); // Continue even if geocoding fails for a profile
              }
            });
          })
        );

        Promise.all(coordinatePromises)
          .then(profileArray => setCoordinates(profileArray.filter(profile => profile && profile.coordinates)))
          .catch(error => console.error('Error during geocoding: ', error));

      } else {
        setMapError('Failed to load profiles.');
      }
    };

    fetchProfiles();
  }, [isScriptLoaded]); // Ensure profiles are fetched after Google script is loaded

  useEffect(() => {
    const map = mapRef.current;
    if (map && selectedArea) {
      const { lat, lng, zoom } = areas[selectedArea];
      map.panTo({ lat, lng });
      map.setZoom(zoom);
    }
  }, [selectedArea]);

  const handleAreaClick = (area) => {
    setSelectedArea(area);
  };

  const handleViewToggle = (mode) => {
    setViewMode(mode);
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
      loading="async" // Set async loading to avoid warnings
      onLoad={() => setIsScriptLoaded(true)} // Set script loaded when Google Maps API is available
    >
      <div className="view-container">
        {/* View Mode Toggle */}
        <div className="view-toggle">
          <button
            onClick={() => handleViewToggle('map')}
            className={`button ${viewMode === 'map' ? 'active' : ''}`}
          >
            Map View
          </button>
          <button
            onClick={() => handleViewToggle('list')}
            className={`button ${viewMode === 'list' ? 'active' : ''}`}
          >
            List View
          </button>
        </div>

        {/* Location Buttons */}
        {viewMode === 'map' && (
          <div className="location-buttons">
            {Object.keys(areas).map(area => (
              <button
                key={area}
                onClick={() => handleAreaClick(area)}
                className={`location-button ${selectedArea === area ? 'active' : ''}`}
              >
                {area.charAt(0).toUpperCase() + area.slice(1)}
              </button>
            ))}
          </div>
        )}

        {/* Map or List View */}
        {viewMode === 'map' ? (
          <div className="map">
            <GoogleMap
              mapContainerStyle={{ height: '500px', width: '100%' }}
              center={areas.england}
              zoom={6}
              onLoad={mapInstance => (mapRef.current = mapInstance)}
            >
              {coordinates.map((profile, index) => (
                <Marker
                  key={index}
                  position={profile.coordinates}
                  onClick={() => setSelectedProfile(profile)}
                />
              ))}

              {selectedProfile && (
                <InfoWindow
                  position={selectedProfile.coordinates}
                  onCloseClick={() => setSelectedProfile(null)}
                >
                  <div className="info-window-card">
                    <h3 className="info-window-city">{selectedProfile.city}</h3>
                    <div className="info-window-details">
                      <p><strong>Website:</strong> <a href={selectedProfile.website} target="_blank" rel="noopener noreferrer" className="info-window-link">{selectedProfile.website}</a></p>
                      <p><strong>TikTok:</strong> <a href={selectedProfile.tiktok} target="_blank" rel="noopener noreferrer" className="info-window-link">{selectedProfile.tiktok}</a></p>
                      <p><strong>Instagram:</strong> <a href={selectedProfile.instagram} target="_blank" rel="noopener noreferrer" className="info-window-link">{selectedProfile.instagram}</a></p>
                    </div>
                    <a href={`/profile/${selectedProfile.user_id}`} className="button">
                      View Profile
                    </a>
                  </div>
                </InfoWindow>
              )}

            </GoogleMap>
            {mapError && <div>{mapError}</div>}
          </div>
        ) : (
          <div className="profiles-list">
            {coordinates.map((profile, index) => (
              <div key={index} className="profile-card">
                <h3 className="profile-city">{profile.city}</h3>
                <div className="profile-details">
                  <p><strong>Website:</strong> <a href={profile.website} target="_blank" rel="noopener noreferrer" className="profile-link">{profile.website}</a></p>
                  <p><strong>TikTok:</strong> <a href={profile.tiktok} target="_blank" rel="noopener noreferrer" className="profile-link">{profile.tiktok}</a></p>
                  <p><strong>Instagram:</strong> <a href={profile.instagram} target="_blank" rel="noopener noreferrer" className="profile-link">{profile.instagram}</a></p>
                </div>
                <a href={`/profile/${profile.user_id}`} className="profile-button">
                  View Profile
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </LoadScript>
  );
};

export default AllProfilesMap;
