import React, { useState, useEffect, useRef } from "react";
import { updateProfile, getProfile, getTags } from '../../utils/api.jsx';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import ConnectInstagramButton from "../instagram/ConnectInstagramButton.jsx";

const libraries = ['places'];

const EditProfile = () => {
  const user_id = localStorage.getItem('userId');
  const [website, setWebsite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [profileError, setProfileError] = useState('');
  const [instagramMessage, setInstagramMessage] = useState(''); // Separate state for Instagram message
  const [radius, setRadius] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [route, setRoute] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const autocompleteRef = useRef(null);
  const [address, setAddress] = useState('');
  const [tags, setTags] = useState([]);
  const [tag_ids, setSelectedTagIds] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (user_id) {
      const fetchData = async () => {
        let response = await getProfile(user_id);
        if (response && !response.errors) {
          setWebsite(response.website);
          setInstagram(response.instagram);
          setTiktok(response.tiktok);
          setRadius(response.radius);
          setStreetNumber(response.street_number);
          setRoute(response.route);
          setCity(response.city);
          setCounty(response.county);
          setPostalCode(response.postal_code);
          setCountry(response.country);
          setSelectedTagIds(response.tag_ids);
          setDescription(response.description);

          const addressString = [
            response.street_number,
            response.route,
            response.city,
            response.county,
            response.postal_code,
            response.country
          ].filter(Boolean).join(', ');

          setAddress(addressString);
        } else {
          let errors = "";
          for (let i = 0; i < response.errors.length; i++) {
            errors += response.errors[i] + ", ";
          }
          setProfileError(errors);
        }

        response = await getTags(user_id);
        if (response && !response.errors) {
          setTags(response || []); // Ensure that tags is an array
        } else {
          let errors = "";
          for (let i = 0; i < response.errors.length; i++) {
            errors += response.errors[i] + ", ";
          }
          setProfileError(errors);
        }        
      };
  
      fetchData();
    }
  }, [user_id]);

  const handleProfileSubmit = async (event) => {
    event.preventDefault();
    const response = await updateProfile(user_id, website, instagram, tiktok, radius, streetNumber, route, city, county, postalCode, country, tag_ids, description);
  
    if (!response.errors) {
      setWebsite(response.website);
      setInstagram(response.instagram);
      setTiktok(response.tiktok);
      setRadius(response.radius);
      setStreetNumber(response.street_number);
      setRoute(response.route);
      setCity(response.city);
      setCounty(response.county);
      setPostalCode(response.postal_code);
      setCountry(response.country);
      setDescription(response.description)
      
      const addressString = [
        response.street_number,
        response.route,
        response.city,
        response.county,
        response.postal_code,
        response.country
      ].filter(Boolean).join(', ');

      setAddress(addressString);
      setProfileError('Profile updated successfully.');
    } else {
      setProfileError(response.errors.join(", "));
    }
  }

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.address_components) {
      place.address_components.forEach(component => {
        const types = component.types;
        if (types.includes('street_number')) {
          setStreetNumber(component.long_name);
        }
        if (types.includes('route')) {
          setRoute(component.long_name);
        }
        if (types.includes('locality') || types.includes('postal_town')) {
          setCity(component.long_name);
        }
        if (types.includes('administrative_area_level_2')) {
          setCounty(component.long_name);
        }
        if (types.includes('postal_code')) {
          setPostalCode(component.long_name);
        }
        if (types.includes('country')) {
          setCountry(component.long_name);
        }
      });

      const addressString = [
        place.address_components.find(c => c.types.includes('street_number'))?.long_name,
        place.address_components.find(c => c.types.includes('route'))?.long_name,
        place.address_components.find(c => c.types.includes('locality') || c.types.includes('postal_town'))?.long_name,
        place.address_components.find(c => c.types.includes('administrative_area_level_2'))?.long_name,
        place.address_components.find(c => c.types.includes('postal_code'))?.long_name,
        place.address_components.find(c => c.types.includes('country'))?.long_name
      ].filter(Boolean).join(', ');

      setAddress(addressString);
    }
  };

  const handleTagChange = (event) => {
    const value = parseInt(event.target.value);
    setSelectedTagIds((prevSelectedTagIds) =>
      prevSelectedTagIds.includes(value)
        ? prevSelectedTagIds.filter((id) => id !== value)
        : [...prevSelectedTagIds, value]
    );
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
      preventGoogleFontsLoading={true}
    >
      <div className="container">
        <form className="form" onSubmit={handleProfileSubmit}>
          <h2 className="title">Edit Profile</h2>
          <h5 className="subtitle">Links</h5>
          <div className="form-group">
            <input
              placeholder="Website"
              type="text"
              id="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              placeholder="Tiktok"
              type="text"
              id="tiktok"
              value={tiktok}
              onChange={(e) => setTiktok(e.target.value)}
            />
          </div>
          <h5 className="subtitle">Address</h5>
          <div className="form-group">
            <input
              placeholder="Radius"
              type="number"
              id="radius"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
            />
          </div>
          <Autocomplete
            onLoad={autocomplete => autocompleteRef.current = autocomplete}
            onPlaceChanged={handlePlaceChanged}
            options={{
              componentRestrictions: { country: 'uk' }
            }}
            className="form-group"
          >
            <input
              type="text"
              placeholder="Enter your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Autocomplete>
          <h5 className="subtitle">Bio</h5>

          <h5 className="subtitle">Select Tags</h5>
          <div className="form-group">
          {tags && tags.length > 0 ? (
            tags.map((tag) => (
              <div key={tag.id}>
                <input
                  type="checkbox"
                  id={`tag-${tag.id}`}
                  name="tags"
                  value={tag.id}
                  checked={tag_ids.includes(tag.id)}
                  onChange={handleTagChange}
                />
                <label htmlFor={`tag-${tag.id}`}>{tag.name}</label>
              </div>
            ))
          ) : (
            <p>No tags available</p>
          )}
        </div>

        <h5 className="subtitle">Description</h5>
        <div className="form-group">
          <textarea
            id="description"
            name="description"
            placeholder="Enter a description..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="5"
            className="content-input"
          />
        </div>

          {profileError && <p className="center-text">{profileError}</p>}
          {instagramMessage && <p className="center-text">{instagramMessage}</p>}
          <div className="form-group">
            <button className="button" type="submit">Update Profile</button>
          </div>
          <div className="form-group">
            <ConnectInstagramButton setMessage={setInstagramMessage} />
          </div>
        </form>
      </div>
    </LoadScript>
  );
}

export default EditProfile;
