import React from 'react';
import useComments from '../../../hook/UseComments';
import { deleteComment } from '../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './CommentStyle.css';

const CommentsComponent = ({ postId, author }) => {
  const { comments, currentPage, setCurrentPage, totalPages } = useComments(postId);
  // const [author, set] = useState([]);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleDeleteComment = async (id) => {
    await deleteComment(id);
  };

  return (
    <div className='post-container'>
      <h2>Comments</h2>
      {comments.length === 0 ? <p>No comments yet.</p> : 
        <>
          <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
            {renderPageNumbers()}
            <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
          </div>

          {comments.map(comment => (
            <div className='post-show' key={comment.id}>
              <span className='creator-tag'>By: {comment.created_by}</span>
              <p className='post-content'>{comment.comment}</p>
              <span className='creator-tag'>{new Date(comment.created_at).toLocaleTimeString()}</span>  

              {(comment.created_by === localStorage.getItem('username') || author === localStorage.getItem('username')) &&
              <button onClick={() => handleDeleteComment(comment.id)} className="delete-button" aria-label="Delete">
                <FontAwesomeIcon icon={faTrash} />
              </button>
              }
            </div>
          ))}

          <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1}>&lt;</button>
            {renderPageNumbers()}
            <button onClick={handleNext} disabled={currentPage === totalPages}>&gt;</button>
          </div>
        </>
      }
    </div>
  );
};

export default CommentsComponent;
