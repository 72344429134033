import React, { useEffect, useState } from 'react';
import './InstagramCarousel.css';
import config from '../../config'; // Importing the config file

const InstagramCarousel = ({ userId }) => {
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchInstagramPhotos = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/instagram/photos/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setPhotos(data.data); // Assuming the response has a 'data' field containing the photos
        } else {
          const errorText = await response.text();
          setError(errorText);
        }
      } catch (error) {
        setError('Error fetching Instagram photos');
      }
    };

    fetchInstagramPhotos();
  }, [userId]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.max(photos.length - 4, 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % Math.max(photos.length - 4, 1));
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Instagram Photos</h2>
      <div className="carousel-container">
        <button className="carousel-button prev" onClick={prevSlide}>❮</button>
        <div className="carousel-slide" style={{ transform: `translateX(-${currentIndex * 20}%)` }}>
          {photos.map((photo, index) => (
            <div
              key={photo.id}
              className={`carousel-image ${index >= currentIndex && index < currentIndex + 5 ? 'active' : ''}`}
              style={{ flex: '0 0 20%' }} // Ensure each image takes 20% of the container width
            >
              <a href={photo.permalink} target="_blank" rel="noopener noreferrer">
                <img src={photo.media_url} alt="Instagram photo" />
              </a>
            </div>
          ))}
        </div>
        <button className="carousel-button next" onClick={nextSlide}>❯</button>
      </div>
    </div>
  );
};

export default InstagramCarousel;
