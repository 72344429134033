import { useEffect, useState } from 'react';
import { createConsumer } from '@rails/actioncable';
import { getComments } from '../utils/api';
import config from '../config';

function useComments(postId, initialPage = 1) {
    const [comments, setComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [totalPages, setTotalPages] = useState(0);

    // Fetch initial comments
    useEffect(() => {
        async function fetchComments() {
            const fetchedComments = await getComments(postId, currentPage);
            setComments(fetchedComments.comments);
            setTotalPages(fetchedComments.total_pages);
        }
        fetchComments();
    }, [postId, currentPage]);

    // Subscribe to ActionCable for real-time updates
    useEffect(() => {
      const cable = createConsumer(config.actionCableUrl);
      const params = { channel: 'CommentsChannel', post_id: postId };
      const subscription = cable.subscriptions.create(params, {
        received(data) {
          if (data.action === 'destroyed') {
            setComments(prevComments => prevComments.filter(comment => comment.id !== data.comment_id));
            setTotalPages(data.total_pages);
          } else if (data.action === 'created') {
            setComments(prevComments => [data.comment, ...prevComments].slice(0, 10));
            setTotalPages(data.total_pages);
          }
        }
      });
    
      return () => {
        subscription.unsubscribe();
      };
    }, [postId]);

    return { comments, currentPage, setCurrentPage, totalPages };
}

export default useComments;
