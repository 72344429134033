import React from 'react';
import '../GeneralStyle.css';

const TermsOfService = () => {
  return(
    <div className="container">
      <h1>Terms of Service for Glimmer</h1>
      <p><strong>Effective Date:</strong> [Insert Date]</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By accessing and using the Glimmer website (the "Service"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you must not use the Service.</p>

      <h2>2. Changes to Terms</h2>
      <p>Glimmer reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service after any changes signifies your acceptance of the updated Terms.</p>

      <h2>3. Use of the Service</h2>

      <h3>Eligibility</h3>
      <p>You must be at least 13 years old to use the Service. By using the Service, you represent and warrant that you meet this age requirement.</p>

      <h3>User Accounts</h3>
      <p>To access certain features of the Service, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

      <h3>Prohibited Conduct</h3>
      <p>You agree not to engage in any of the following prohibited activities:</p>
      <ul>
        <li>Using the Service for any illegal purpose or in violation of any applicable laws.</li>
        <li>Posting or transmitting content that is harmful, offensive, or inappropriate.</li>
        <li>Interfering with the operation of the Service or any other user's enjoyment of the Service.</li>
      </ul>

      <h2>4. User-Generated Content</h2>

      <h3>Ownership</h3>
      <p>You retain ownership of any content you post on the Service. However, by posting content on the Service, you grant Glimmer a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content in connection with the operation of the Service.</p>

      <h3>Responsibility</h3>
      <p>You are solely responsible for the content you post. Glimmer is not responsible for any content posted by users of the Service.</p>

      <h2>5. Privacy</h2>
      <p>Your use of the Service is also governed by our <a href="/privacy">Privacy Policy</a>. By using the Service, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>

      <h2>6. Intellectual Property</h2>
      <p>All content, trademarks, and data on the Service, including but not limited to software, text, graphics, logos, and icons, are the property of Glimmer or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any of the content on the Service without our express written permission.</p>

      <h2>7. Termination</h2>
      <p>Glimmer reserves the right to suspend or terminate your access to the Service at any time, without notice, for any reason, including if we believe you have violated these Terms.</p>

      <h2>8. Disclaimers</h2>
      <p>The Service is provided on an "as is" and "as available" basis. Glimmer makes no warranties or representations about the accuracy or completeness of the content on the Service or the content of any sites linked to the Service.</p>

      <h2>9. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, Glimmer shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Service, whether based on contract, tort, strict liability, or otherwise.</p>

      <h2>10. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of [Placeholder Country], without regard to its conflict of law principles.</p>

      <h2>11. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> [Contact Information Placeholder]</li>
        <li><strong>Address:</strong> [Business Address Placeholder]</li>
      </ul>
    </div>
  )
}

export default TermsOfService;